import(/* webpackMode: "eager" */ "/root/web/BAKWeb-master/web-phim/Frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/root/web/BAKWeb-master/web-phim/Frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/root/web/BAKWeb-master/web-phim/Frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/root/web/BAKWeb-master/web-phim/Frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/root/web/BAKWeb-master/web-phim/Frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/root/web/BAKWeb-master/web-phim/Frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/root/web/BAKWeb-master/web-phim/Frontend/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/root/web/BAKWeb-master/web-phim/Frontend/node_modules/react-alice-carousel/lib/alice-carousel.css");
;
import(/* webpackMode: "eager" */ "/root/web/BAKWeb-master/web-phim/Frontend/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/root/web/BAKWeb-master/web-phim/Frontend/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/root/web/BAKWeb-master/web-phim/Frontend/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["Row1AdsComponent","LeftSideAdsComponent","RightSideAdsComponent","PopupAdsComponent","FooterAdsComponent"] */ "/root/web/BAKWeb-master/web-phim/Frontend/src/components/AdsComponents/AdsComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/web/BAKWeb-master/web-phim/Frontend/src/layout/admin/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/web/BAKWeb-master/web-phim/Frontend/src/layout/general/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/web/BAKWeb-master/web-phim/Frontend/src/lib/AntdRegistry.tsx");
;
import(/* webpackMode: "eager" */ "/root/web/BAKWeb-master/web-phim/Frontend/src/styles/spinner.css");
